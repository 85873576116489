<template>
  <div class="row">
    <div class="col-12 col-lg-5 c g" style="margin-top: 50px">
      <div class="card card-body text-center">
        <img
          src="../../assets/images/robot.png"
          style="width: 100px; margin: 0px auto"
          alt=""
        />
        <br />
        <div class="alert alert-danger" style="padding: 20px">
          عفواً، تجاوزت الحد المسموح به للمعلمين لديك
        </div>
        <br />
        <div class="col-12 text-center">
          <a
            :href="api2 + '/user/login?return=/user/teachers-update'"
            target="_blank"
            class="btn btn-success btn-lg"
          >
            دفع الفارق الآن
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      api2: localStorage.getItem("api2"),
    };
  },
};
</script>
